import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { Col, Row } from "react-bootstrap"
import Slide from "../utility/slide_content"
import MillionCard from "./OnemillionCard"

function SuggestedBytes(props) {
  var itemsToBeShown = 4
  var currentItem = 0

  return (
    <>
      <StaticQuery
        query={graphql`
          query EpisodesMainVideoSuggestMillion {
            SuperOps {
              episodes(
                where: { superpodPage: bytes }
                orderBy: episodeNumber_DESC
              ) {
                id
                audioDuration
                superpodPage
                episodeNumber
                image {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 540 } }
                    }
                  )
                }
                title
                guestName
                guestTitle
                superPodGuestTextTheme
                slug
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          // <div className="column-container">
          <Row className="card-gap">
            {data.SuperOps.episodes.map(episode => {
              const {
                id,
                episodeNumber,
                audioDuration,
                image,
                title,
                guestName,
                guestTitle,
                superPodGuestTextTheme,
                slug,
              } = episode

              return (
                <Col
                  lg={6}
                  sm={12}
                  className={`${id === props.bytesId ? "dspnonesp" : ""}`}
                >
                  <>
                    <div
                      style={{
                        display:
                          currentItem <= itemsToBeShown ? "block" : "none",
                      }}
                    >
                      <span className="dspnonesp">{currentItem++} </span>
                      <Slide>
                        <MillionCard data={episode} slug={props.slug} />
                      </Slide>
                    </div>
                  </>
                </Col>
              )
            })}
          </Row>
        )}
      />
    </>
  )
}

export default SuggestedBytes
