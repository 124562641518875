import { graphql, Link, navigate, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import LazyLoad from "react-lazyload"
import Close from "../assets/images/superpod/sp-close.png"
import Frame from "../components/common/frame"
import Links from "../components/common/links"
import SocialShare from "../components/social_share"
import SuggestedBytes from "../components/superpod/suggested_bytes_million"
import Slide from "../components/utility/slide_content"

function SuperpodPodcast({ pageContext }) {
  const { episode, pagename } = pageContext

  const [aSum, setaSum] = useState(false)
  const [aDesc, setaDesc] = useState(false)
  const [aNotes, setaNotes] = useState(false)

  const setaNotesActive = () => {
    setaNotes(true)
    setaDesc(false)
    setaSum(false)
  }

  const setaDescActive = () => {
    setaNotes(false)
    setaDesc(true)
    setaSum(false)
  }

  const setaSumActive = () => {
    setaNotes(false)
    setaDesc(false)
    setaSum(true)
  }

  const {
    id,
    title,
    summary,
    whatYouWillLearn,
    highlights,
    embedded,
    type,
    seo,
    slug,
  } = episode

  useEffect(() => {
    if (!!summary) {
      setaSumActive()
    } else {
      setaDescActive()
    }
  }, [summary])

  const shareTitle = seo.title
  const shareTags = []

  return (
    <>
      <Frame seo={seo} bodyClassName="ofy-hidden bg-black" IsFooterVisble="No">
        <StaticQuery
          query={graphql`
            query EpisodesMainTemplate {
              site {
                siteMetadata {
                  url
                  twitterHandle
                }
              }
              SuperOps {
                links(where: { section: "Superpod" }) {
                  section
                  icon {
                    url
                  }
                  slug
                  linkName
                }
              }
            }
          `}
          render={data => (
            <>
              <div>
                {type === "Audio" && (
                  <>
                    <section className="sp-pop-content">
                      {/* <Link className="no-deco" onClick=> */}
                      <LazyLoad throttle={200} height={200}>
                        <img
                          src={Close}
                          className="close objcvr"
                          alt="Close"
                          width="8"
                          height="8"
                          onClick={() => navigate(-1)}
                        />
                      </LazyLoad>
                      {/* </Link> */}
                      <center>
                        <div className="audio-content">
                          <section className="sp-podcasts">
                            <Container>
                              <div className="mt32">
                                <Slide delay="200">
                                  <div className="sp-podcast-block">
                                    {parse(embedded.html)}
                                    <div className="action-links">
                                      {/* <span> Listen on: </span> */}
                                      <Links
                                        items={data.SuperOps.links[0]}
                                        iconOnly
                                        name
                                      />
                                    </div>
                                  </div>
                                </Slide>
                              </div>
                              <Slide delay="200">
                                <span className="content">
                                  <h3> {title} </h3>
                                </span>
                              </Slide>
                            </Container>
                          </section>
                          <div className="ml12 mt10 display-in posabsp dsk">
                            {/* <Slide delay="200"> */}
                            <SocialShare
                              socialConfig={{
                                twitterHandle:
                                  data.site.siteMetadata.twitterHandle,
                                config: {
                                  url: `${data.site.siteMetadata.url}superpod-podcast/${slug}`,
                                  title: shareTitle,
                                },
                              }}
                              tags={shareTags}
                              direction="hor"
                            />
                            {/* </Slide> */}
                          </div>

                          <div className="ml12 mt10 mob aud-mob">
                            {/* <Slide delay="200"> */}
                            <SocialShare
                              socialConfig={{
                                twitterHandle:
                                  data.site.siteMetadata.twitterHandle,
                                config: {
                                  url: `${data.site.siteMetadata.url}superpod-podcast/${slug}`,
                                  title: shareTitle,
                                },
                              }}
                              tags={shareTags}
                              direction="vrt"
                            />
                            {/* </Slide> */}
                          </div>

                          <div className="display-in aud-right">
                            <Slide delay="200">
                              <div className="ctabs">
                                {!!summary && (
                                  <div
                                    className={`tbct ${aSum && "active"}`}
                                    onClick={setaSumActive}
                                    onKeyDown={setaSumActive}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    Summary
                                  </div>
                                )}

                                {!!whatYouWillLearn && (
                                  <div
                                    className={`tbct ${aDesc && "active"}`}
                                    onClick={setaDescActive}
                                    onKeyDown={setaDescActive}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    Show notes
                                  </div>
                                )}

                                {!!highlights && (
                                  <div
                                    className={`tbct ${aNotes && "active"}`}
                                    onClick={setaNotesActive}
                                    onKeyDown={setaNotesActive}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    Transcript
                                  </div>
                                )}

                                {aSum && (
                                  <>
                                    <span className="content">
                                      {!!summary && (
                                        <> {parse(summary.html)} </>
                                      )}
                                    </span>
                                  </>
                                )}

                                {aDesc && (
                                  <>
                                    <span className="content">
                                      {!!whatYouWillLearn && (
                                        <> {parse(whatYouWillLearn.html)} </>
                                      )}
                                    </span>
                                  </>
                                )}

                                {aNotes && (
                                  <>
                                    <span className="content">
                                      {!!highlights && (
                                        <> {parse(highlights.html)} </>
                                      )}
                                    </span>
                                  </>
                                )}
                              </div>
                            </Slide>
                          </div>
                        </div>
                      </center>
                    </section>
                  </>
                )}

                {type !== "Audio" && (
                  <>
                    <section className="sp-pop-content">
                      <Container>
                        <Link className="no-deco" to="/superpod-bytes">
                          <LazyLoad throttle={200} height={200}>
                            <img
                              src={Close}
                              className="close objcvr"
                              alt="Close"
                              width="8"
                              height="8"
                            />
                          </LazyLoad>
                        </Link>
                        <center>
                          <div className="video-content">
                            <Slide delay="200">
                              <h1 className="text-left"> {title} </h1>
                            </Slide>
                            <Slide delay="200">{parse(embedded.html)}</Slide>
                            <div className="ml12 mt10">
                              <Slide delay="200">
                                <SocialShare
                                  socialConfig={{
                                    twitterHandle:
                                      data.site.siteMetadata.twitterHandle,
                                    config: {
                                      url: `${data.site.siteMetadata.url}superpod-bytes/${slug}`,
                                      title: shareTitle,
                                    },
                                  }}
                                  tags={shareTags}
                                  direction="vtr"
                                />
                              </Slide>
                            </div>

                            <div className="sp-bytes-recent">
                              <h2> RECENT EPISODES </h2>
                              <SuggestedBytes
                                returnUrl="/superpod/superpod-bytes/"
                                bytesId={id}
                                slug={pagename}
                              />
                            </div>
                          </div>
                        </center>
                      </Container>
                    </section>
                  </>
                )}
              </div>
            </>
          )}
        />
      </Frame>
    </>
  )
}

export default SuperpodPodcast
